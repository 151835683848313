.navibar{
    background: rgba(0,0,0,0);
    padding: 0.5rem 0;
    position:sticky;
    top: 0;
    z-index: 30;
    backdrop-filter: blur(50px);

    .content{
        max-width: 1300px;
        display: flex;
        margin: 0 auto;
        padding: 1rem 0;
        margin: 0 auto;
 .logo{
    margin-right: 24rem;
    width: 10rem;
 }
        .navi-links{
            display: flex;
            align-items: center;
            gap:2rem;
            justify-content: space-between;
           
            
        }
        .contack-btn{
          
            display: flex;
            align-items: center;
            font-size: 0.9rem;
            font-weight: 500;
            justify-content: center;
            color: #fff;
            background:linear-gradient(98deg,#a993fe 0%,#7e61e7 10%);
            padding: 0.6rem 2rem;
            border: none;
            border-radius: 0.2rem;
            outline: 1.5px solid transparent;
            cursor: pointer;
            transition: all 0.3 ease;
            margin-left: 22px;
        }
        .contack-btn:hover{
            color: #a993fe;
            background: #000;
            outline: 1.5px solid #a993fe;
        }
    }
    .ms-auto{
      display: flex;
      gap: 4rem;
      flex-direction: row;
      .navi-item{
        transition: all 0.3 ease;
        font-size: 1.2rem;
        font-weight: 500;
        color: #fff;
        cursor: pointer;
        text-decoration: none;
        opacity: 0.7;
        
    }
    .navi-item:hover{
      opacity: 1;
    }
    .active-navi-item{
      opacity: 1;
      text-decoration: none;
      font-size: 1.2rem;
      color:  #a993fe;
    }
    .navi-item::before{
        content: '';
        width: 2rem;
        height: 0.2rem;
        background:linear-gradient(98deg,#a993fe 0%,#7e61e7 10%);
        border-radius: 0.5rem;
        position: absolute;
        bottom: 0.5rem;
        opacity: 0;
        transform: translateX(-1.5rem);
        transition:all 0.3s ease;
    } 
    .navi-item:hover::before{
        width: 5rem;
        transform: translateX(0);
        opacity: 1;
    }
    }

}

.menu-btn{
  width: 2.5rem;
  height: 2.5rem;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 0.4rem;
  color: #fff;
  background: linear-gradient(90deg,#a993fe -0.13%,#7e61e7 99.87%);
  line-height: 0;
  cursor: pointer;
  transition: all 0.4s ease;
  display: none;
}
.menu-btn:hover{
  color: #a993fe;
  background: #000;
  border: 1px solid #a993fe;
}



@media  (max-width:1024px){
  
  .navibar .content{

    .logo{
      margin-right: 10rem;
    }
    .navi-links{

      .navi-item,
    .active-navi-item{
    font-size: 1rem;
    }
      .contack-btn{
        font-size: 0.8rem;
        font-weight: 500;
        width: 7rem;
        height: 2rem;
      }
      .contack-btn:hover{
        color: #a993fe;
        background: #000;
        outline: 1.5px solid #a993fe;
      }
    }
.logo{
  width: 11rem;
  height: 4rem;
}


  }
}
@media (max-width:768px) {
  .navibar{
    .content{
      .logo{
        margin-right: 5rem;
      }
    }
.ms-auto{
gap: 2.5rem;
}
  
  .navi-links{
    .ms-auto{
    .navi-item,
    .active-navi-item{
    font-size: 0.8rem;
    }
  }
    .contack-btn{

      font-size: 1rem;
      font-weight: 500;
      width: 2rem;
      
     
    }
  }
}
}

@media (max-width:425px) {

  .navibar{
    .content{
      position: relative;
      .menu-btn{
        display: block;
      
        position: absolute;
        right: 10px;
      }
      .navi-links{
        display: none;
      }
      .logo{
        
          width: 10rem;
          height: auto;
        
      }
    }
  }
 
}