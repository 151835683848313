.skills-container{
    margin: 3rem;
    margin-top: 7rem;

h5{
    font-size: 1.5rem;
}


.skills-content{
    margin-top: 3rem;
    display: flex;
    align-items: flex-start;
    gap: 2rem;
    .skills-info{
        flex: 1;
    }
    .skills{
        display: grid;
        grid-gap:2rem;
        grid-template-columns: repeat(2, 1fr);
     
        flex:1
    }
}
}
.skills-container::after,
.skills-container::before{
    content:"";
    width: 20rem;
    height: 20rem;
    border-radius: 20.125rem;
    background: #7c66e3;
    position: absolute;
    z-index: -1;
    filter: blur(8rem);
}
.skills-container::after{
    bottom:40rem;
    right:-2rem;
}
.skills-container::before{
   background: #9e51b9;
    bottom: 10rem;
    left: 0rem;
}
@media (max-width:425px) {
    .skills-content{
        flex-direction: column;

        
    }

}


  
