.contack-container{
margin: 3rem;
margin-top: 7rem;

.contack-content{
    display: flex;
    margin-top: 2rem;
    gap: 2rem;
    
}


    h5{
        font-size:1.5rem ;
    }
}
@media (max-width:425px) {
    
    .contack-content{
        flex-direction: column;
    }
}