.work-container{
    margin: 3rem;
    margin-top: 7rem;

h5{
    font-size: 1.5rem;
}


}
.work-container-content{
    position: relative;

    .arrow-right,
    .arrow-left{
        width: 2.2rem;
        height: 2.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color:#6751b9;
        border-radius: 0.65rem;
        border: 1.5px solid #6751b9;
        background: #130f22;
        position: absolute;
        top: 45%;
        z-index: 2;
        cursor: pointer;
    }
    .arrow-left{
        left: 1rem;
    }
    .arrow-right{
        right: 1rem;
    }
    .arrow-right span,
    .arrow-left span{
        font-size: 2rem;
        font-weight: 500;
    }

}